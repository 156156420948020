<div class="additional-components round-corner width-container corner-display-container"
     @addAnimation
     *ngIf="dataReady"
>
    <h1 class="helvetica">My PC Setup</h1>

    <table class="helvetica">
        <tr>
            <th>Component</th>
            <th>Name</th>
            <th>Buy</th>
        </tr>
        <tr *ngFor="let entry of pcSetupEntries">
            <th>{{entry.partName}}</th>
            <th>{{entry.partDetail}}</th>
            <th><a class="orange-underline-anchor" href="{{entry.partPurchaseLink}}" target="_blank">Link</a></th>
        </tr>
    </table>

    <div class="top-right-icon x-icon" (click)="exit()">
        <p>&#10005;</p>
    </div>

</div>
