<app-navbar></app-navbar>

<div id="top-section" class="width-container">
    <div id="logo-dual" style="margin-top: 10%">
        <div>
            <img src="{{apiService.fileURL((aboutContent | async).imageUrl)}}" width="100%">
        </div>
        <div class="neutral-card">
            <h1 class="theme-color medium-large-text helvetica">Welcome!</h1>
            <h3 class="helvetica-normal small-text"> {{(aboutContent | async).content}}</h3>
        </div>
    </div>
</div>

<div id="ios-showoff" class="width-container">
    <h3 class="medium-text helvetica">As a result -</h3>
    <h1 class="theme-color helvetica large-text">I'm a Kick-ass iOS Developer.</h1>
    <div id="stats-showoff" *ngIf="data">
        <div id="stats-col-1" class="flex">
            <h1 class="large-text helvetica theme-color">{{data.appsWorkedOn}}</h1>
            <h3 class="medium-text helvetica">apps <br> worked on</h3>
        </div>
        <div id="stats-col-2" class="flex">
            <h1 class="large-text helvetica theme-color">{{data.commercialAppsWorkedOn}}</h1>
            <h3 class="medium-text helvetica">commercial apps <br> worked on</h3>
        </div>
        <div id="stats-col-3" class="flex">
            <h1 class="large-text helvetica theme-color">{{data.appsPublished}}</h1>
            <h3 class="medium-text helvetica">apps published <br> on App Store</h3>
        </div>
    </div>
</div>

<div id="ios-skills" class="width-container">
    <div id="ios-graphic" ></div>
    <div id="ios-content">
        <h3 class="medium-text helvetica">And I'm experienced in these areas of iOS/Swift Dev: </h3>
        <h1 class="medium-text helvetica" style="margin-bottom: 5px">Languages:
            <span class="theme-color">Swift&nbsp;</span>
            <span style="color: dodgerblue">Objective-C</span>
        </h1>
        <div *ngIf="data">
            <h3 class="helvetica small-text"  *ngFor="let skill of data.iosSkills" style="padding: 0; margin: 0.2%">
                <span>{{skill.name}}&nbsp;&nbsp;</span>
                <span style="color: darkgrey">{{skill.details.join(", ")}}&nbsp;</span>
            </h3>
        </div>
        <h3 class="medium-text helvetica">View more details
            <a class="orange-underline-anchor" [routerLink]="['/apps']">here</a>.
        </h3>
    </div>
</div>

<div id="web-skills">
    <div class="width-container">
        <h1 class="medium-large-text helvetica">Web Design? I'm good at that too.</h1>
        <h3 class="small-text helvetica">I have experience using these frameworks:</h3>

        <div class="horizontal-scroll-outer" inViewport (inViewportAction)="webSkillsInViewport($event)">
            <div *ngIf="showWebSkillsTutorial">
                <ng-lottie [options]="options" width="100%" class="overlay"></ng-lottie>
            </div>
            <div class="web-skills-scroll horizontal-scroll-outer horizontal-scroll-inner"
                 *ngFor="let webSkill of webSkillsFormatted"
            >
                <h3 class="theme-color small-text helvetica">{{webSkill.category}}</h3>
                <div class="horizontal-scroll-inner" style="width: 100px" *ngFor="let skill of webSkill.details">
                    <img width="100px" height="100px" src="{{apiService.fileURL(skill.imageUrl)}}">
                    <h3 class="extra-small-text helvetica" style="text-align: center">{{skill.text}}</h3>
                </div>
            </div>
        </div>

        <h3 class="small-text helvetica">In fact, this website is programmed with Angular.</h3>
    </div>
</div>

<div style="background-color: black">
    <div class="width-container" style="padding-top: 3%; padding-bottom: 3%">
        <h1 class="medium-large-text helvetica" style="color: white">Graphic Design is my passion, too.</h1>
        <div id="graphics">
            <div id="graphics-left">
                <h3 class="medium-text helvetica-normal">I had been a graphic designer <br/> for Rensselaer Union for</h3>
                <img width="200px" src="assets/images/resume/3-point-5.png">
                <h3 class="medium-text helvetica-normal">years.</h3>
                <br>
                <h3 class="medium-text helvetica"> Here are my skill sets:</h3>
                <div id="graphics-skills" *ngIf="data">
                    <div *ngFor="let graphicSkill of data.graphicSkills">
                        <h3 class="helvetica extra-small-text">{{graphicSkill.name}}</h3>
                        <hr style="background-color: lightgrey; height: 1px">
                        <h3 style="color: lightgrey"
                           class="helvetica-normal extra-small-text"
                           *ngFor="let entry of graphicSkill.details"
                        >{{entry}}</h3>
                    </div>
                </div>
                <h3 class="medium-text helvetica"> View my portfolio
                    <a [routerLink]="['/graphic-design']" class="orange-underline-anchor-light">here</a> .
                </h3>
            </div>
            <br>
            <div id="graphics-right">
                <img width="100%" src="assets/images/resume/graphic-decoration.png"/>
            </div>
        </div>
    </div>
</div>


<div id="experiences" class="width-container">
    <h1 class="medium-large-text helvetica">Work experience is up next:</h1>
    <app-timeline-block [experiences]="experiences"></app-timeline-block>
</div>

<div id="education" >
    <div class="width-container">
        <h1 class="medium-large-text helvetica">Here are my education details:</h1>
        <div class="edu-styles">
            <img src="assets/images/resume/rpi_seal.jpg">
            <div>
                <h1 class="medium-text helvetica">Rensselaer Polytechnic Institute</h1>
                <h3 class="small-text helvetica text-padding">2016 - 2020</h3>
                <h3 class="extra-small-text helvetica-normal text-padding">B.S. Computer Science</h3>
                <h3 class="extra-small-text helvetica-normal text-padding">B.S. Computer and Systems Engineering</h3>
            </div>
        </div>
        <div class="resume-cv-styles">
            <h1 class="medium-text helvetica">My Resume and CV:</h1>
            <a style="margin-right: 2%" class="theme-button zoom-fx" href="{{resumeURL}}">RESUME</a>
            <a class="theme-button zoom-fx" href="{{cvURL}}">CV</a>
        </div>

    </div>
</div>

<app-footer [showTopBorder]="true"></app-footer>
