<div id="nav" class="menu-wrap" [ngClass]="{'dark-nav': darkThemed, 'light-nav': !darkThemed}">
    <nav class="menu">
        <ul class="clearfix">
            <li><a [routerLink]="['/']">HOME</a></li>
            <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'about'}">ABOUT</a></li>
            <li><a [routerLink]="['/resume']">RESUME</a></li>
            <li><a [routerLink]="['/apps']">APPS</a></li>
            <li><a [routerLink]="['/graphic-design']">GRAPHIC DESIGN</a></li>
            <li>
                <div class="dropdown">
                    <a class="dropbtn" [routerLink]="['/projects']">PROJECTS</a>
                    <div class="dropdown-content" [ngClass]="{'dark-dropdown': darkThemed, 'light-dropdown': !darkThemed}">
                        <a [routerLink]="['/projects']">All</a>
                        <a *ngFor="let project of simplifiedProjects | async" href="{{project.url}}">{{project.name}}</a>
                    </div>
                </div>
            </li>
            <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'about'}">CONTACT</a></li>
            <li><a [routerLink]="['/login']">LOGIN</a></li>
        </ul>
    </nav>
</div>

<div id="top_mobile_nav"
     [ngClass]="{'dark-top-mobile-nav': darkThemed, 'light-top-mobile-nav': !darkThemed}"
     [ngStyle]="{'border-bottom': bottomBorderStyle}"
>
    <img src="assets/images/navbar/navicon.png" (click)="toggleExpansion()">
</div>

<header style="height: 50%" [@shrinkOrExpand]="expanded ? 'expanded' : 'shrinked'">
    <nav id="mobile_nav" [ngClass]="{'dark-mobile-nav': darkThemed, 'light-mobile-nav': !darkThemed}">
        <div class="horizScrollDiv" style="margin-left: 20px">
            <a [routerLink]="['/']"><img src="assets/images/navbar/home.png"></a>
            <li><a [routerLink]="['/']">HOME</a></li>
        </div>
        <div class="horizScrollDiv">
            <a [routerLink]="['/']" [queryParams]="{scrollToId: 'about'}">
                <img src="assets/images/navbar/aboutIcon.png">
            </a>
            <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'about'}">ABOUT</a></li>
        </div>
        <div class="horizScrollDiv">
            <a [routerLink]="['/resume']"><img src="assets/images/navbar/resumelogo.png"></a>
            <li><a [routerLink]="['/resume']">RESUME</a></li>
        </div>
        <div class="horizScrollDiv">
            <a [routerLink]="['/apps']"><img src="assets/images/navbar/apps.png"></a>
            <li><a [routerLink]="['/apps']">APPS</a></li>
        </div>
        <div class="horizScrollDiv">
            <a [routerLink]="['/graphic-design']"><img src="assets/images/navbar/splashIcon.png"></a>
            <li><a [routerLink]="['/graphic-design']">GRAPHIC DESIGN</a></li>
        </div>
        <div class="horizScrollDiv">
            <a [routerLink]="['/projects']"><img src="assets/images/navbar/gearIcon.png"></a>
            <li><a [routerLink]="['/projects']">PROJECTS</a></li>
        </div>
        <div class="horizScrollDiv" style="margin-right: 20px">
            <a [routerLink]="['/']" [queryParams]="{scrollToId: 'contacts'}">
                <img src="assets/images/navbar/phone.png">
            </a>
            <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'contacts'}">CONTACT</a></li>
        </div>
    </nav>
</header>
