<div id="timeline-wrapper" *ngFor="let experience of experiences; let i=index">
    <div id="exp-left">
        <img width="80%" src="{{apiService.fileURL(experience.imageLink)}}">
        <div id="position-company">
            <h3 class="small-text helvetica" style="margin-top: 10px">{{experience.position}}</h3>
            <h3 class="extra-small-text helvetica-normal"
                style="margin: -15px 0 0 0"
            >💼&nbsp;&nbsp;{{experience.company}}</h3>
            <h3 id="mobile-time" class="helvetica-normal extra-small-text">🕒&nbsp;&nbsp;{{experience.time}}</h3>
        </div>
    </div>
    <div id="exp-mid">
        <div class="ml-auto mr-auto">
            <div class="dot"></div>
            <div class="separator-line"
                 [ngStyle]="{'height': i === experiences.length - 1 ? '85%' : '100%'}"
            ></div>
        </div>
    </div>
    <div id="exp-right">
        <h3 id="normal-time" class="mohave small-text">{{experience.time | uppercase}}</h3>
        <h3 class="extra-small-text helvetica-normal"
            *ngFor="let expEntry of experience.accomplishments"
            [innerHTML]="expEntry"
        ></h3>
    </div>
</div>
