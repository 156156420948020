<div class="width-container" id="about-main" [@addAnimation]>
    <div id="profile-image">
        <img width="240px" height="240px" src="{{apiService.fileURL((aboutContent | async).imageUrl)}}">
    </div>

    <div id="intro-text" class="corner-display-container">
        <h1 class="helvetica">About Me & Contact</h1>
        <p class="helvetica">{{(aboutContent | async).content}}</p>
        <p class="helvetica">View My Resume 📜
            <a class="orange-underline-anchor" [routerLink]="['/resume']">here</a>.
        </p>

        <div class="top-right-icon x-icon" (click)="exit()">
            <p>&#10005;</p>
        </div>

        <p class="helvetica">
            Feel free to contact me @ {{mail}} or click
            <a class="orange-underline-anchor" href="mailto:{{mail}}">
                here
            </a>
        </p>
    </div>
</div>
