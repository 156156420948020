<div [ngStyle]="{'border-top': topBorderStyle}"></div>

<div id="footer_container"
     [ngClass]="{'footer-width-container': !minimizedVersionShown}"
>
<!--    width container can cause issues when minimized. Hence only enable it when non-minimized -->
    <div id="footerx">
        <div id="section1">
            <div id="navigation">
                <ul>
                    <div (click)="toggle(1)" [ngStyle]="{'color': primaryFontColor}" class="rotation">
                        <div *ngIf="minimizedVersionShown" [@rotate45Degrees]="pagesExpanded ? 'rotated' : 'default'">
                            <h4>+</h4>
                        </div>
                        <div [ngClass]="{'left-space': minimizedVersionShown}"><h4>Pages</h4></div>
                    </div>
                    <div [@shrinkOrExpand]="pagesExpanded ? 'expanded' : 'shrinked'">
                        <li><a [routerLink]="['/']">Home</a></li>
                        <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'about'}">About</a></li>
                        <li><a [routerLink]="['/resume']">Resume</a></li>
                        <li><a [routerLink]="['/apps']">Apps </a></li>
                        <li><a [routerLink]="['/graphic-design']">Graphic Design </a></li>
                        <li><a [routerLink]="['/']" [queryParams]="{scrollToId: 'contacts'}">Contact Me</a></li>
                    </div>
                </ul>
            </div>
            <div id="projects_footer">
                <ul>
                    <div (click)="toggle(2)" [ngStyle]="{'color': primaryFontColor}" class="rotation">
                        <div *ngIf="minimizedVersionShown" [@rotate45Degrees]="projectsExpanded ? 'rotated' : 'default'">
                            <h4>+</h4>
                        </div>
                        <div [ngClass]="{'left-space': minimizedVersionShown}"><h4>Projects</h4></div>
                    </div>
                    <div [@shrinkOrExpand]="projectsExpanded ? 'expanded' : 'shrinked'">
                        <li><a [routerLink]="['/projects']">All Projects</a></li>
                        <li *ngFor="let project of simplifiedProjects | async">
                            <a href="{{project.url}}">{{project.name}}</a>
                        </li>
                    </div>
                </ul>
            </div>
        </div>
        <div id="section2">
            <div id="graphics_footer">
                <ul>
                    <div (click)="toggle(3)" [ngStyle]="{'color': primaryFontColor}" class="rotation">
                        <div *ngIf="minimizedVersionShown" [@rotate45Degrees]="designsExpanded ? 'rotated' : 'default'">
                            <h4>+</h4>
                        </div>
                        <div [ngClass]="{'left-space': minimizedVersionShown}"><h4>Designs</h4></div>
                    </div>
                    <div [@shrinkOrExpand]="designsExpanded? 'expanded' : 'shrinked'">
                        <li *ngFor="let graphic of simplifiedGraphics | async">
                            <a href="{{graphic.url}}">{{graphic.name}}</a>
                        </li>
                        <li><a href="https://www.behance.net/MrProLi">Behance</a></li>
                        <li><a href="https://www.flickr.com/photos/154270674@N02/">Flickr</a></li>
                    </div>
                </ul>
            </div>
            <div id="social_media_footer">
                <ul>
                    <div (click)="toggle(4)" [ngStyle]="{'color': primaryFontColor}" class="rotation">
                        <div *ngIf="minimizedVersionShown" [@rotate45Degrees]="socialsExpanded ? 'rotated' : 'default'">
                            <h4>+</h4>
                        </div>
                        <div [ngClass]="{'left-space': minimizedVersionShown}"><h4>Social</h4></div>
                    </div>
                    <div [@shrinkOrExpand]="socialsExpanded ? 'expanded' : 'shrinked'">
                        <li><a href="https://www.facebook.com/li.jingwei.94">Facebook</a></li>
                        <li><a href="https://www.instagram.com/ljw980105">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/in/andersonli2/">LinkedIn</a></li>
                        <li><a href="https://twitter.com/MrProLi">Twitter</a></li>
                        <li><a href="https://www.youtube.com/channel/UC33WP6-Af8Oy7SGzZm5mxng">Youtube</a></li>
                        <li><a href="https://github.com/ljw980105/">GitHub</a></li>
                    </div>
                </ul>
            </div>
        </div>
    </div>

    <div id="logo-copyright">
        <div id="personal_logo" [routerLink]="['/']">
            <img
                alt="Anderson Li's Logo"
                src="{{logoUrl}}"
            >
        </div>
        <div id="copyright-container">
            <h4
                [ngStyle]="{'color': primaryFontColor}"
            > &copy; Anderson Li 2017-{{year}}<br/>Proudly Created with Angular</h4>
        </div>
    </div>
</div>
