<div id="dual-app-wrapper">

    <div id="item-1" class="app">
        <img width="200px" height="200px" src="{{apiService.fileURL(app1.imageLink)}}">
        <h2>{{app1.name}}</h2>
        <p [innerHTML]="app1.description"></p>
        <p><strong>{{app1.description2}}</strong></p>
        <div class="anchor-tag-alignment" *ngIf="app1.link != null">
            <a target="_blank" href="{{app1.link}}">{{app1.linkTitle}}&nbsp;</a>
        </div>
    </div>

    <div id="item-2" class="app" *ngIf="app2 != null">
        <img width="200px" height="200px" src="{{apiService.fileURL(app2.imageLink)}}">
        <h2>{{app2.name}}</h2>
        <p [innerHTML]="app2.description"></p>
        <p><strong>{{app2.description2}}</strong></p>
        <div class="anchor-tag-alignment" *ngIf="app2.link != null">
            <a target="_blank" href="{{app2.link}}"> {{app2.linkTitle}}&nbsp;</a>
        </div>
    </div>

</div>
