<app-navbar></app-navbar>

<canvas #topCanvas id="top-canvas" height="20"></canvas>

<div id="main-wrapper">
    <div id="social_media_container" class="width-container">
        <div id="social_media">
            <ul>
                <a target="_blank" href="https://www.facebook.com/li.jingwei.94">
                    <img src="assets/images/home/facebook_logo.svg" width="25" height="25">
                </a>
                <a target="_blank" href="https://www.linkedin.com/in/andersonli2/">
                    <img src="assets/images/home/linkedin_logo.svg" width="25" height="25">
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UC33WP6-Af8Oy7SGzZm5mxng">
                    <img src="assets/images/home/youtube_logo.svg" width="25" height="25">
                </a>
                <a target="_blank" href="https://github.com/ljw980105/">
                    <img src="assets/images/home/github_logo.svg" width="25" height="25">
                </a>
                <a target="_blank" href="https://stackoverflow.com/users/9132818/jingwei-li">
                    <img src="assets/images/home/stackoverflow_logo.svg" width="25" height="25">
                </a>
            </ul>
        </div>
    </div>
    <div class="width-container" id="main">
        <img alt="Anderson Li, iOS Engineer" src="assets/images/home/NameGraphic.svg" class="main-logo">
        <div style="display: flex">
            <img class="main-individual-img" alt="personal photo" src="assets/images/home/2024-Website-Photo.jpg">
            <img class="main-individual-img" alt="personal photo" src="assets/images/home/2024-Website-Photo-BW.jpg">
        </div>
        <img alt="Anderson Li, iOS Engineer" src="assets/images/home/NameGraphic.svg" class="mobile-logo">
    </div>
</div>

<canvas #bottomCanvas height="20"></canvas>

<div id="wrapper" class="width-container-home-cards">
    <div class="column-1">
        <div class="neutral-card">
            <div class="flex center_div">
                <img src="assets/images/home/app_icon.svg">
                <p class="font-for-emoji clear-text-paddings orange-hover-fx"
                   (click)="navigateTo('apps')"
                >App <br> Portfolio</p>
            </div>
        </div>

        <div class="neutral-card">
            <div class="flex center_div">
                <img src="assets/images/home/pc_icon.svg">
                <p class="font-for-emoji clear-text-paddings orange-hover-fx"
                   (click)="togglePCSetup()"
                >My PC <br> Specs</p>
            </div>
        </div>

        <div class="neutral-card">
            <div class="flex center_div">
                <img src="assets/images/home/image_icon.svg">
                <p class="font-for-emoji clear-text-paddings orange-hover-fx"
                   (click)="navigateTo('graphic-design')"
                >Graphic <br> Design</p>
            </div>
        </div>
    </div>

    <div class="column-2">
        <!--        Projects-->
        <div class="neutral-card">
            <div class="flex center_div">
                <img src="assets/images/home/project_icon.svg">
                <p class="font-for-emoji clear-text-paddings orange-hover-fx"
                   (click)="navigateTo('projects')"
                >My <br> Projects</p>
            </div>
        </div>

        <div class="neutral-card">
            <div class="flex center_div">
                <img src="assets/images/home/info_icon.svg">
                <p class="font-for-emoji clear-text-paddings orange-hover-fx"
                   (click)="toggleAboutPage()"
                >About & <br> Contact</p>
            </div>
        </div>

    </div>
</div>

<app-pc-setup id="pc-setup" (closeComponent)="togglePCSetup()" *ngIf="showPCSetup"></app-pc-setup>

<app-about id="about" (closeComponent)="toggleAboutPage()" *ngIf="showAboutPage"></app-about>

<app-footer [showTopBorder]="false"></app-footer>
