<div class="width-container">
    <h1 class="mohave">COCKTAILS</h1>

    <div id="container">
        <div *ngFor="let cocktail of cocktails">
            <h2 class="mohave">{{cocktail.name | uppercase}}</h2>
            <p class="helvetica">{{cocktail.description}}</p>
        </div>
    </div>
</div>
